import React from "react";
import Accordion, { AccordionItem, AccordionTitle, AccordionContent } from ".";

const AccordionWrap = () => {
  return (
    <Accordion>
      <AccordionItem id="one">
        <AccordionTitle id="one">
          <br />
          <br />
          <h2>Ai enabled RCM tech</h2> <br />
        </AccordionTitle>
        <p>
          Welcome to Grey codes Solutions, your premier destination for
          AI-enabled revenue cycle management solutions. Our cutting-edge
          software leverages artificial intelligence and advanced algorithms to
          streamline and optimize the revenue cycle process for healthcare
          providers.
          <br />
        </p>
        <AccordionTitle id="one">Key Features:</AccordionTitle>
        <AccordionContent id="one">
          1. Automated Claims Processing: Our AI-powered software automates the
          claims processing workflow, reducing manual errors and accelerating
          reimbursement cycles. <br />
          <br />
          2. Intelligent Billing and Coding: [Grey codes solutions ] utilizes AI
          technology to accurately assign medical codes and optimize billing
          processes, ensuring compliance and maximizing revenue.
          <br />
          <br />
          3. Predictive Analytics: Harness the power of predictive analytics to
          forecast revenue trends, identify potential issues, and make
          data-driven decisions to improve financial performance.
          <br />
          <br />
          4. Denial Management: Our software analyzes claims denials using AI
          algorithms to identify patterns and root causes, allowing providers to
          take proactive measures to prevent future denials.
          <br />
          <br />
          5.Performance Monitoring: Track key performance indicators and monitor
          revenue cycle metrics in real-time to identify areas for improvement
          and optimize revenue capture.
        </AccordionContent>
        <br />
        <AccordionTitle id="one">
          Why Choose Grey codes Solutions :
        </AccordionTitle>
        <br />
        <AccordionContent id="two">
          1. Advanced AI Technology: Grey codes Solutions incorporates the
          latest advancements in artificial intelligence and machine learning to
          deliver unparalleled efficiency and accuracy in revenue cycle
          management.
          <br />
          <br />
          2.Customizable Solutions: Our software is highly customizable to meet
          the unique needs and preferences of healthcare providers. Whether you
          are a small practice or a large healthcare system, Grey codes
          Solutions can be tailored to fit your specific requirements.
          <br />
          <br />
          3.Seamless Integration: Grey codes Solutions seamlessly integrates
          with existing EHR/EMR systems and practice management software,
          ensuring smooth implementation and minimal disruption to your
          operations.
          <br />
          <br />
          4.Enhanced Revenue Optimization: By automating repetitive tasks,
          minimizing errors, and providing actionable insights, Grey codes
          Solutions helps healthcare providers optimize revenue and improve
          financial performance.
          <br />
          Unlock the full potential of your revenue cycle with Grey codes
          Solutions. Contact us today to learn more and schedule a demo.
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default AccordionWrap;

import React from "react";
import Healthcare from "../../components/healthcare";

const AccordionContainer = () => {
  return (
    <div className="faq-section section-py">
      <div className="container">
        <div className="row">
          <div className="col-xl-10">
            <Healthcare />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccordionContainer;

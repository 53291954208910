import React from "react";
import Accordion, {
  AccordionItem,
  AccordionTitle,
  AccordionContent,
} from "../accrodion";

const AccordionWrap = () => {
  return (
    <Accordion>
      <AccordionItem id="one">
        <AccordionTitle id="one">
          <br />
          <br />
        </AccordionTitle>
        <p>
          At Grey codes solutions , we specialize in providing expert guidance
          and strategic insights to investors looking to navigate the complex
          landscape of healthcare investments. With our deep industry knowledge
          and extensive experience, we offer tailored solutions to help our
          clients make informed investment decisions and maximize returns in the
          dynamic healthcare sector.
          <br />
        </p>
        <AccordionTitle id="one">Our Services:</AccordionTitle>
        <AccordionContent id="one">
          1. Investment Strategy Development: We work closely with our clients
          to develop customized investment strategies that align with their
          goals, risk tolerance, and market trends. Whether you are looking to
          invest in pharmaceuticals, biotechnology, medical devices, or
          healthcare services, we have the expertise to guide you towards the
          most promising opportunities. <br />
          <br />
          2. Market Research and Analysis: Our team conducts thorough market
          research and analysis to identify emerging trends, potential growth
          areas, and investment opportunities within the healthcare sector. By
          staying ahead of market dynamics, we help our clients capitalize on
          lucrative investment prospects.
          <br />
          <br />
          3. Due Diligence: We conduct comprehensive due diligence assessments
          to evaluate the financial, operational, and regulatory aspects of
          potential healthcare investments. Our rigorous analysis ensures that
          our clients have a clear understanding of the risks and opportunities
          associated with each investment opportunity.
          <br />
          <br />
          4. Portfolio Management: We provide ongoing portfolio management
          services to monitor investment performance, mitigate risks, and
          optimize returns. Our proactive approach enables our clients to adapt
          to changing market conditions and capitalize on new opportunities as
          they arise.
          <br />
          <br />
        </AccordionContent>
        <br />
        <AccordionTitle id="one">Why Choose Us :</AccordionTitle>
        <br />
        <AccordionContent id="two">
          1. Industry Expertise: Our team consists of seasoned professionals
          with deep expertise in the healthcare sector, ensuring that our
          clients receive the highest quality advice and guidance.
          <br />
          <br />
          2.Tailored Solutions: We understand that every investor has unique
          goals and requirements. That is why we offer personalized solutions
          that are specifically tailored to meet the needs of each client.
          <br />
          <br />
          3.Proven Track Record: With a track record of success in healthcare
          investment consultancy, we have helped numerous clients achieve their
          investment objectives and maximize their returns.
          <br />
          4.Commitment to Excellence: At Grey codes solutions, we are committed
          to excellence in everything we do. We strive to exceed our clients
          expectations by delivering exceptional service and value at every step
          of the investment process.
          <br />
          Whether you are a seasoned investor looking to diversify your
          portfolio or a newcomer seeking expert guidance in the healthcare
          sector, Grey codes solutions is here to help you achieve your
          investment goals. Contact us today to learn more about our services
          and how we can assist you in unlocking the full potential of
          healthcare investments.
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default AccordionWrap;

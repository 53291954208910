import React from "react";
import Accordion, {
  AccordionItem,
  AccordionTitle,
  AccordionContent,
} from "../accrodion";

const AccordionWrap = () => {
  return (
    <Accordion>
      <AccordionItem id="one">
        <AccordionTitle id="one">
          <br />
          <br />
          <h2>Payer Solutions</h2> <br />
        </AccordionTitle>
        <p>
          Welcome to Grey codes Solutions , your comprehensive solution for
          healthcare insurance management.
          <br />
          Grey codes solutions is a cutting-edge software platform designed to
          streamline and optimize the management of healthcare insurance
          processes. From claims processing to member enrollment and provider
          management, our software offers robust features and intuitive tools to
          simplify the complexities of healthcare insurance administration.
        </p>
        <AccordionTitle id="one">Key Features:</AccordionTitle>
        <AccordionContent id="one">
          1. Claims Processing: Our software automates the claims processing
          workflow, allowing insurers to efficiently manage and adjudicate
          claims with accuracy and.speed. With built-in validation checks and
          real-time status updates, claims processing becomes faster and more
          efficient. <br />
          <br />
          2. Member Enrollment: Simplify the enrollment process for members with
          our user-friendly interface. From online enrollment forms to
          eligibility verification, our software streamlines the enrollment
          process and ensures compliance with regulatory requirements.
          <br />
          <br />
          3. Provider Management: Manage provider networks seamlessly with our
          provider management module. From credentialing and contract management
          to provider directory maintenance, our software centralizes provider
          information and facilitates communication between insurers and
          providers.
          <br />
          <br />
          4. Billing and Payment: Our software offers comprehensive billing and
          payment functionalities, including premium billing, invoice
          generation, and payment processing. With automated billing cycles and
          integrated payment gateways, insurers can streamline the billing
          process and improve cash flow management.
          <br />
          <br />
          5.Reporting and Analytics: Gain valuable insights into your insurance
          operations with our advanced reporting and analytics tools. Track key
          performance indicators, analyze claims data, and identify trends to
          make informed business decisions and optimize performance. quickly to
          changing market conditions and customer needs.
        </AccordionContent>
        <br />
        <AccordionTitle id="one">
          Why Choose Grey codes Solutions :
        </AccordionTitle>
        <br />
        <AccordionContent id="two">
          1. Scalable and Customizable: Our software is highly scalable and
          customizable to meet the unique needs of insurers of all sizes.
          Whether you are a small insurance provider or a large enterprise, Grey
          codes Solutions can be tailored to fit your specific requirements..
          <br />
          <br />
          2.Seamless Integration: Grey codes Solutions seamlessly integrates
          with existing systems and third-party applications, ensuring smooth
          implementation and minimal disruption to your operations..
          <br />
          <br />
          3. Compliance and Security: We prioritize compliance with industry
          regulations and standards, including HIPAA and GDPR. Our software also
          features robust security measures to protect sensitive data and ensure
          data privacy.
          <br />
          <br />
          4.User-Friendly Interface: With an intuitive user interface and easy
          navigation, Grey codes Solutions is designed for maximum usability and
          efficiency. Training requirements are minimal, allowing users to
          quickly adapt to the software and maximize productivity.
          <br />
          Whether you are an insurance company, third-party administrator, or
          healthcare provider, Grey codes Solutions is your all-in-one solution
          for healthcare insurance management. Contact us today to schedule a
          demo and experience the power of Grey codes Solutions for yourself.
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default AccordionWrap;

import React from "react";
import PropTypes from "prop-types";
import Button from "../../../components/button";
import IconBox from "../../../components/icon-box";
import SectionTitle from "../../../components/section-title";
import HomeData from "../../../data/home.json";

const IconBoxContainer = ({ classOption }) => {
  return (
    <div className={`feature-section position-relative ${classOption}`}>
      <div className="container custom-container">
        <div className="row g-0 align-items-center">
          <div className="col-xl-6 col-lg-8 mx-auto mb-3 text-center">
            <SectionTitle
              classOption="title-section"
              subTitle="FEATURES"
              title="How can we enhance your  <span class='text-primary'>experience</span>?"
              excerptClassOption="mb-10"
              excerpt="Grey codes Solutions is a modern-day tech enabler offering comprehensive range of digital
              transformation services in insurance Segment across Middle East and Africa.  With an
              Expertise on Medical, Motor and General Insurance, Grey codes Offer digital transformation
              and Consulting Services to insurance Houses/Brokers/ healthcare providers."
            />

            {/* <Button
              classOption="btn btn-lg btn-dark btn-hover-dark"
              text="Get Started"
              path="/about"
            /> */}
            {/* <img
              className="path-img"
              src={`${process.env.PUBLIC_URL}/images/banner/1.png`}
              alt="images_not_found"
            /> */}
          </div>

          <div className="col-12">
            <div id="grid" className="grid row mb-n7">
              {HomeData[2].iconBox &&
                HomeData[2].iconBox.map((single, key) => {
                  return <IconBox key={key} data={single} />;
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

IconBoxContainer.propTypes = {
  classOption: PropTypes.string,
};

IconBoxContainer.defaultProps = {
  classOption: "section-pb",
};

export default IconBoxContainer;

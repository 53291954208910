import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import { useState } from "react";
import axios from "axios";

const ContactForm = () => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [subject, setSubject] = useState();
  const [message, setMessage] = useState();
  const sendMail = () => {
    axios
      .post("http://localhost:5001/", {
        params: {
          name,
          email,
          subject,
          message,
        },
      })
      .then(() => {
        console.log("success");
      })
      .catch(() => {
        console.log("failure");
      });
  };

  const { register, errors } = useForm({
    mode: "onBlur",
  });
  return (
    <Fragment>
      <form
        id="contactForm"
        className="row"
        action="https://getform.io/f/a17a2715-d7ee-4ac4-8fcb-12f1eed43b2c"
        method="POST"
      >
        <div className="col-12 col-sm-6 mb-7">
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            placeholder="Your Name*"
            ref={register({ required: "Name is required" })}
            onChange={(e) => setName(e.target.value)}
          />
          {errors.name && <p>{errors.name.message}</p>}
        </div>
        <div className="col-12 col-sm-6 mb-7">
          <input
            type="text"
            className="form-control"
            id="email"
            name="email"
            placeholder="Your Email*"
            onChange={(e) => setEmail(e.target.value)}
            ref={register({
              required: "Email is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "invalid email address",
              },
            })}
          />
          {errors.email && <p>{errors.email.message}</p>}
        </div>

        <div className="col-12 mb-9">
          <textarea
            className="form-control massage-control"
            name="message"
            id="massage"
            cols="30"
            rows="10"
            placeholder="Message"
            ref={register({
              required: "Message is required",
            })}
          ></textarea>
          {errors.message && <p>{errors.message.message}</p>}
        </div>
        <div className="col-12">
          <button
            id="contactSubmit"
            type="submit"
            className="btn btn-dark btn-hover-dark"
            data-complete-text="Well Done!"
            onClick={sendMail}
          >
            Send Message
          </button>
        </div>
      </form>
    </Fragment>
  );
};

export default ContactForm;

import React from "react";
import Accordion, {
  AccordionItem,
  AccordionTitle,
  AccordionContent,
} from "../accrodion";

const AccordionWrap = () => {
  return (
    <Accordion>
      <AccordionItem id="one">
        <AccordionTitle id="one">
          <br />
          <br />
          <h2>Ai enabled RCM tech</h2> <br />
        </AccordionTitle>
        <p>
          Welcome to Grey codes Solutions , your premier destination for
          AI-enabled revenue cycle management solutions. Our cutting-edge
          software leverages artificial intelligence and advanced algorithms to
          streamline and optimize the revenue cycle process for healthcare
          providers.
        </p>
        <AccordionTitle id="one">Key Features:</AccordionTitle>
        <AccordionContent id="one">
          1. Risk Assessment: Our AI-powered algorithms analyze vast amounts of
          data to assess risk factors accurately. By evaluating historical data,
          market trends, and other relevant variables, our software helps
          insurers predict potential risks and make more informed underwriting
          decisions. <br />
          <br />
          2. Automated Underwriting: Streamline the underwriting process with
          our automated underwriting capabilities. Our software automates
          routine underwriting tasks, such as application processing and risk
          evaluation, reducing manual effort and accelerating policy issuance.
          <br />
          <br />
          3. Predictive Analytics: Grey codes Solutions harnesses the power of
          predictive analytics to forecast future trends and outcomes in
          insurance underwriting. By analyzing historical data patterns and
          market dynamics, our software helps insurers anticipate emerging risks
          and opportunities.
          <br />
          <br />
          4. Fraud Detection: Detect and prevent insurance fraud with our fraud
          detection algorithms. Our software analyzes patterns and anomalies in
          insurance applications and claims data to identify potential instances
          of fraud, helping insurers mitigate financial losses and protect
          against fraudulent activity.
          <br />
          <br />
          5. Real-Time Decision Making: Enable real-time decision-making with
          our AI-enabled underwriting solutions. Our software provides insurers
          with instant insights and recommendations, allowing them to respond
          quickly to changing market conditions and customer needs.
        </AccordionContent>
        <br />
        <AccordionTitle id="one">
          Why Choose Grey codes Solutions :
        </AccordionTitle>
        <br />
        <AccordionContent id="two">
          1. Advanced AI Technology: Grey codes Solutions harnesses the latest
          advancements in artificial intelligence and machine learning to
          deliver unparalleled accuracy and efficiency in insurance
          underwriting.
          <br />
          <br />
          2.Customizable Solutions: Our software is highly customizable to meet
          the unique needs and preferences of insurers. Whether you are a small
          insurance company or a large enterprise, Grey codes Solutions can be
          tailored to fit your specific requirements.
          <br />
          <br />
          3.Seamless Integration: Grey codes Solutions seamlessly integrates
          with existing systems and third-party applications, ensuring smooth
          implementation and minimal disruption to your operations.
          <br />
          <br />
          4.Enhanced Efficiency and Profitability: By automating routine tasks
          and leveraging predictive analytics, Grey codes Solutions helps
          insurers streamline underwriting processes, reduce operational costs,
          and optimize profitability.
          <br />
          <br />
          Whether you are an insurance company, underwriting agency, or
          insurance broker, [Grey codes solutions ] is your trusted partner for
          AI-enabled insurance underwriting solutions. Contact us today to learn
          more and schedule a demo.
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default AccordionWrap;

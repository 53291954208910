import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import AccordionContainer from "../containers/accordion";
import NewsletterArea from "../containers/global/newsletter";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";

const Solutions = () => {
  return (
    <React.Fragment>
      <Layout>
        <SEO title="Grey codes – Solutions" />
        <div className="wrapper">
          <Header />
          {/* <PageBanner
            title="Ai Enabled Underwriting solutions"
            excerpt="Welcome to Grey codes solutions , your premier destination for AI-enabled insurance underwriting solutions"
            image="./images/faq/1.png"
          /> */}
          <AccordionContainer />
          <NewsletterArea />
          <Footer />
          <ScrollToTop />
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default Solutions;

import React from "react";
import RcmSolutions from "../../components/accrodion/rcmsolutions";

const AccordionContainer = () => {
  return (
    <div className="faq-section section-py">
      <div className="container">
        <div className="row">
          <div className="col-xl-10">
            <RcmSolutions />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccordionContainer;

import React from "react";
import PropTypes from "prop-types";

const ServiceDetails = ({ data }) => {
  return (
    <div className="service-wrap">
      <div className="service-thumb bg-light text-center">
        <img src={`${process.env.PUBLIC_URL}/${data.image}`} alt="" />
      </div>
      <div className="title-section">
        <div className="service-content-wrap">
          {data.body.map((single, key) => {
            return (
              <div
                className="desc"
                key={key}
                dangerouslySetInnerHTML={{ __html: single }}
              ></div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

ServiceDetails.propTypes = {
  data: PropTypes.object,
};

export default ServiceDetails;

import { NavLink } from "react-router-dom";

const MainMenu = () => {
  return (
    <nav className="main-menu d-none d-lg-block">
      <ul className="d-flex">
        <li>
          <NavLink exact to="/">
            Home
          </NavLink>
        </li>
        <li>
          <NavLink to={process.env.PUBLIC_URL + "/about"}>About</NavLink>
        </li>
        <li>
          <NavLink to={process.env.PUBLIC_URL + "/service"}>
            Digital Transformation
          </NavLink>
          <li />
          <ul className="sub-menu">
            <li className="sub-menu-item">
              <NavLink
                className="sub-menu-link"
                to={process.env.PUBLIC_URL + "/service"}
              >
                Insurance solutions:
              </NavLink>
            </li>

            <li className="sub-menu-item">
              <NavLink
                className="sub-menu-link"
                to={process.env.PUBLIC_URL + "/service"}
              >
                TPA Suit
              </NavLink>
            </li>
            <li className="sub-menu-item">
              <NavLink
                className="sub-menu-link"
                to={process.env.PUBLIC_URL + "/service"}
              >
                Sales&Product Management
              </NavLink>
            </li>
            <li className="sub-menu-item">
              <NavLink
                className="sub-menu-link"
                to={process.env.PUBLIC_URL + "/service"}
              >
                Underwriting solutions
              </NavLink>
            </li>
          </ul>
        </li>
        <li>
          <NavLink to="/">Healthcare Solutions</NavLink>
          <ul className="sub-menu">
            <li className="sub-menu-item">
              <NavLink
                className="sub-menu-link"
                to={process.env.PUBLIC_URL + "/solution/:id"}
              >
                Medical Billing Services
              </NavLink>
              <NavLink
                className="sub-menu-link"
                to={process.env.PUBLIC_URL + "/payer-solution"}
              >
                RCM technologies
              </NavLink>
              <NavLink
                className="sub-menu-link"
                to={process.env.PUBLIC_URL + "/rcm-solution"}
              >
                Process Automation
              </NavLink>
            </li>
            {/* <li className="sub-menu-item">
              <NavLink
                className="sub-menu-link"
                to={process.env.PUBLIC_URL + "/team"}
              >
                team
              </NavLink>
            </li> */}
          </ul>
        </li>
        <li>
          <NavLink to="/">Consultation</NavLink>

          <ul className="sub-menu">
            <li className="sub-menu-item">
              <NavLink
                className="sub-menu-link"
                to={process.env.PUBLIC_URL + "/healthcare"}
              >
                Tech Consultancy
              </NavLink>
            </li>
            <li className="sub-menu-item">
              <NavLink
                className="sub-menu-link"
                to={process.env.PUBLIC_URL + "/healthcare"}
              >
                Healthcare Investments
              </NavLink>
            </li>
            <li className="sub-menu-item">
              <NavLink
                className="sub-menu-link"
                to={process.env.PUBLIC_URL + "/healthcare"}
              >
                Turn Key Projects
              </NavLink>
            </li>
            {/* <li className="sub-menu-item">
              <NavLink
                className="sub-menu-link"
                to={process.env.PUBLIC_URL + "/blog-left-sidebar"}
              >
                blog grid left sidebar
              </NavLink>
            </li> */}
            {/* <li className="sub-menu-item">
              <NavLink
                className="sub-menu-link"
                to={process.env.PUBLIC_URL + "/blog-right-sidebar"}
              >
                blog grid right sidebar
              </NavLink>
            </li> */}
            {/* <li className="sub-menu-item">
              <NavLink
                className="sub-menu-link"
                to={process.env.PUBLIC_URL + "/blog-details/1"}
              >
                blog details
              </NavLink>
            </li> */}
          </ul>
        </li>
        <li>
          <NavLink to={process.env.PUBLIC_URL + "/contact"}>Contact</NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default MainMenu;
